import React from 'react';
import { Global, css } from '@emotion/core';
import Header from './header';
const Layout = ({ children }) => (
  <>
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
        }
        * + * {
          margin-top: 1rem;
        }
        html,
        body {
          margin: 0;
          color: #04b4b4;
          font-family: 'Special Elite', cursive;
          font-size: 18px;
          line-height: 1.4;
        }
        /*remove div for main div gatsby mounts*/
        > div {
          margin-top: 0;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: #222;
          line-height: 1.1;

          + * {
            margin-top: 0.5rem;
          }
        }

        strong {
          color: #222;
        }

        li {
          margin-top: 0.25rem;
        }
      `}
    />
    {/*<link*/}
    {/*  href="https://fonts.googleapis.com/css2?family=Special+Elite&display=swap"*/}
    {/*  rel="stylesheet"*/}
    {/*/>*/}
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;1,200&display=swap"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />
    <Header />
    <main
    css={css`
      margin-top: 0;
    `}
    >
      <div>{children}</div>
    </main>
  </>
);

export default Layout;
