import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Helmet from "react-helmet";
import SEOIcon from "../images/EZ_logo.png"
const App = () => (
  <Layout>
    <Helmet>
      <html lang="en" />
      <title>EZ Sizing</title>
      <meta name="description" content="Coalescing global online shopping" />
      <link rel="canonical" href="https://ezsizing.com"/>
      <link
        rel="icon"
        type="image/png"
        href={`https://ezsizing.com${SEOIcon}`}
      />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

      <script type="application/ld+json">
        {`"@context": "https://schema.org/",
        "@type": "WebSite",
        name: "Ez Sizing",
        url:"https://ezsizing.com",
        sameAs: [
      "https://twitter.com/ezsizing",
      "https://www.youtube.com/ezsizing",
      "https://www.facebook.com/ezsizing",
      "https://www.instagram.com/ezsizing",
      ],`
        }
      </script>

      <meta property="og:type" content="website" />
      <meta name="twitter:creator" content="@ezsizing" />
      <meta property="og:url" content="https://ezsizing.com" />
      <meta property="og:title" content="EZ Sizing" />
      <meta property="og:description" content="Coalescing global online shopping" />
      <meta property="og:image" content={`https://ezsizing.com${SEOIcon}`} />
      <meta property="og:image:width" content="5001" />
      <meta property="og:image:height" content="2618" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:site_name" content="Ezsizing" />
      <meta property="article:publisher" content="https://www.facebook.com/ezsizing" />
      <meta property="og:locale" content="en_US" />
    </Helmet>
    <div className="homepageWrapper">
      <div className="welcome"> welcome to EZSizing</div>
      <div className="social">
        <Link
          style={{ textDecoration: 'none', color: "#04b4b4" }}
          href="mailto:ezsizing@gmail.com"
        >
          Contact us to learn more!
        </Link>
        Stay in touch with us!
        <div className="socialButton">
          <Link
            href="https://www.facebook.com/ezsizing"
            className="fa fa-facebook"
            id="fontawesome"
          />
          <Link
            href="https://twitter.com/ezsizing"
            className="fa fa-twitter"
            id="fontawesome"
          />
          <Link
            href="mailto:ezsizing@gmail.com"
            className="fa fa-google"
            id="fontawesome"
          />
          <Link
            href="https://www.linkedin.com/in/ezsizing/"
            className="fa fa-linkedin"
            id="fontawesome"
          />
          <Link
            href="https://www.instagram.com/ezsizing/"
            className="fa fa-instagram"
            id="fontawesome"
          />
          <Link
            href="https://www.reddit.com/user/ezsizing/"
            className="fa fa-reddit"
            id="fontawesome"
          />
        </div>
      </div>
    </div>
    <div className="allRights">
    <div className="rightIndex"> 2020 © All Rights Reserved.</div>
    </div>
  </Layout>
);

export default App;
